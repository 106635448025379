var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "kafKa信息采集",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": _vm.saveKafa,
          "head-save-back": function ($event) {
            return _vm.saveAll("back")
          },
          "head-back": _vm.toback,
          "head-start": _vm.start,
          "head-stop": _vm.stop,
        },
      }),
      _c(
        "div",
        {
          staticClass: "newZtFormBox",
          staticStyle: { "background-color": "white" },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.stepsActive == 0,
                  expression: "stepsActive == 0",
                },
              ],
              ref: "step1Form",
              attrs: {
                rules: _vm.rules1,
                model: _vm.addform,
                "label-width": "120px",
              },
            },
            [
              _c("div", { staticStyle: { height: "10px" } }),
              _c(
                "el-collapse",
                {
                  staticStyle: { margintop: "10px" },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "1" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v("\n                源信息\n              "),
                          ]
                        ),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "名称", prop: "serverName" },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addform.serverName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addform, "serverName", $$v)
                                      },
                                      expression: "addform.serverName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "状态", prop: "status" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        disabled: "",
                                        placeholder: "状态",
                                        error: _vm.$t(
                                          "OfflinedataCollection.label.Databasename"
                                        ),
                                      },
                                      model: {
                                        value: _vm.addform.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.addform, "status", $$v)
                                        },
                                        expression: "addform.status",
                                      },
                                    },
                                    _vm._l(
                                      _vm.kafkaStatusList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: item.dictKey,
                                          attrs: {
                                            label: item.dictValue,
                                            value: parseInt(item.dictKey),
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "brokerList",
                                    label: "集群地址",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: _vm.addform.brokerList,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addform, "brokerList", $$v)
                                      },
                                      expression: "addform.brokerList",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "topic", label: "TOPIC地址" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: _vm.addform.topic,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addform, "topic", $$v)
                                      },
                                      expression: "addform.topic",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "描述" } },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: _vm.addform.describes,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addform, "describes", $$v)
                                      },
                                      expression: "addform.describes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "2" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v(
                              "\n                目标信息\n              "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "OfflinedataCollection.label.Databasename"
                                    ),
                                    prop: "writeDatasourceId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: _vm.$t(
                                          "OfflinedataCollection.label.Databasename"
                                        ),
                                        error: _vm.$t(
                                          "OfflinedataCollection.label.Databasename"
                                        ),
                                      },
                                      on: { change: _vm.dbChangeFn1 },
                                      model: {
                                        value: _vm.addform.writeDatasourceId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addform,
                                            "writeDatasourceId",
                                            $$v
                                          )
                                        },
                                        expression: "addform.writeDatasourceId",
                                      },
                                    },
                                    _vm._l(_vm.dbList, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.desc,
                                          value: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "OfflinedataCollection.label.Datatablename"
                                    ),
                                    prop: "writeTable",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: _vm.$t(
                                          "OfflinedataCollection.label.Datatablename"
                                        ),
                                        error: _vm.$t(
                                          "OfflinedataCollection.label.Datatablename"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.getColumnFn1(
                                            _vm.addform.writeTable,
                                            _vm.colmnList1
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.addform.writeTable,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addform,
                                            "writeTable",
                                            $$v
                                          )
                                        },
                                        expression: "addform.writeTable",
                                      },
                                    },
                                    _vm._l(
                                      _vm.biaoList1,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.table_name,
                                            value: item.table_name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 8 } }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-tabs",
                { attrs: { type: "border-card" } },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "源保存字段" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.colmnListAdd },
                        },
                        [_vm._v("\n                新增\n              ")]
                      ),
                      _c("el-button", { attrs: { size: "small" } }, [
                        _vm._v("\n                删除\n              "),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "1" } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "step0Savetable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.colmnList,
                                border: "",
                                size: "small",
                              },
                              on: { select: _vm.selectstep0Savetable },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "55" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "字段名",
                                  prop: "targetColumn",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          model: {
                                            value: scope.row.targetColumn,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "targetColumn",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.targetColumn",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "类型", prop: "sourceType" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              filterable: "",
                                              placeholder: "请选择类型",
                                              error: _vm.$t(
                                                "OfflinedataCollection.label.Datatablename"
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.setKafkaMainfield(
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.sourceType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "sourceType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.sourceType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.kafkaMainfieldTypesList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.dictValue,
                                                  value: item.dictKey,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "字段注释",
                                  prop: "targetComment",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          model: {
                                            value: scope.row.targetComment,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "targetComment",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.targetComment",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "对应关系" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "1" } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "step2Table",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.colmnList1,
                                border: "",
                                size: "small",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "55" },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "字段名称", prop: "name" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "字段注释",
                                  prop: "sourceComment",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "对应关系", prop: "duiying" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { clearable: "" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.selectTableComm(
                                                  scope.row,
                                                  _vm.colmnList
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.sourceColumn,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "sourceColumn",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.sourceColumn",
                                            },
                                          },
                                          _vm._l(
                                            _vm.colmnList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.targetColumn,
                                                  value: item.targetColumn,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.kafkaChildfield, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.stepsActive == 0,
                    expression: "stepsActive == 0",
                  },
                ],
                attrs: {
                  rules: _vm.rules1,
                  model: item.form,
                  "label-width": "120px",
                },
              },
              [
                _c(
                  "el-collapse",
                  {
                    staticStyle: { margintop: "10px" },
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { name: "2" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "i",
                            {
                              staticClass:
                                "avue-group__title avue-group__header",
                              staticStyle: {
                                color: "#999999 !important",
                                "font-style": "normal",
                                padding: "0 10px",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-document",
                                staticStyle: {
                                  "font-size": "20px",
                                  padding: "0 10px",
                                },
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.targetColumn) +
                                  "目标信息\n              "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "OfflinedataCollection.label.Databasename"
                                      ),
                                      prop: "writeDatasourceId",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          disabled: "",
                                          placeholder: _vm.$t(
                                            "OfflinedataCollection.label.Databasename"
                                          ),
                                          error: _vm.$t(
                                            "OfflinedataCollection.label.Databasename"
                                          ),
                                        },
                                        on: { change: _vm.dbChangeFn1 },
                                        model: {
                                          value: item.writeDatasourceId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "writeDatasourceId",
                                              $$v
                                            )
                                          },
                                          expression: "item.writeDatasourceId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dbList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.desc,
                                              value: item.name,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "OfflinedataCollection.label.Datatablename"
                                      ),
                                      prop: "writeTable",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          placeholder: _vm.$t(
                                            "OfflinedataCollection.label.Datatablename"
                                          ),
                                          error: _vm.$t(
                                            "OfflinedataCollection.label.Datatablename"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.getColumnFn1(
                                              item.writeTable,
                                              item.linkList
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.writeTable,
                                          callback: function ($$v) {
                                            _vm.$set(item, "writeTable", $$v)
                                          },
                                          expression: "item.writeTable",
                                        },
                                      },
                                      _vm._l(
                                        _vm.biaoList1,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.table_name,
                                              value: item.table_name,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 8 } }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-tabs",
                      { attrs: { type: "border-card" } },
                      [
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "源保存字段" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.childernListAdd(item.childrenTab)
                                  },
                                },
                              },
                              [_vm._v("\n                新增\n              ")]
                            ),
                            _c("el-button", { attrs: { size: "small" } }, [
                              _vm._v("\n                删除\n              "),
                            ]),
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "1" } },
                              [
                                _c(
                                  "el-table",
                                  {
                                    ref: "step0Savetable",
                                    refInFor: true,
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: item.childrenTab,
                                      border: "",
                                      size: "small",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { type: "selection", width: "55" },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "字段名",
                                        prop: "targetColumn",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  model: {
                                                    value:
                                                      scope.row.targetColumn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "targetColumn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.targetColumn",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "类型",
                                        prop: "sourceType",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder: "请选择类型",
                                                      error: _vm.$t(
                                                        "OfflinedataCollection.label.Datatablename"
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.setKafkaMainfield(
                                                          scope.row,
                                                          item.childrenTab,
                                                          true
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.sourceType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "sourceType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.sourceType",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.kafkaChildfieldTypeList,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.dictValue,
                                                          value: item.dictKey,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "字段注释",
                                        prop: "targetComment",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  model: {
                                                    value:
                                                      scope.row.targetComment,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "targetComment",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.targetComment",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "对应关系" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "1" } },
                              [
                                _c(
                                  "el-table",
                                  {
                                    ref: "step2Table",
                                    refInFor: true,
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: item.linkList,
                                      border: "",
                                      size: "small",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { type: "selection", width: "55" },
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "字段名", prop: "name" },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "字段注释",
                                        prop: "sourceComment",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "对应关系" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: { clearable: "" },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.selectTableComm(
                                                          scope.row,
                                                          item.childrenTab
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.sourceColumn,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "sourceColumn",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.sourceColumn",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.childrenTab,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label:
                                                            item.targetColumn,
                                                          value:
                                                            item.targetColumn,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }